import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que canvia de color marró olivaci, marró dàtil o marró rogenc quan està hidratat tornant-se marró grisenc o daurat quan està sec. Capell de 3 a 6 cm de diàmetre, amb un mamelló central present tant inicialment quan és cònic com posteriorment quan passa a pla. Les làmines són adnades, a vegades quasi lliures, primer de color pàl·lid i després rosades per la caiguda de les espores. Aquestes són rosades en massa, de 10-14 x 8-9,5 micres. El peu és llarg, retorçat cap a la base, pruïnós cap a dalt i cotonós cap a la base.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      